<template lang="html">

  <section class="card-publicacion-full grid" v-if="dataContent">
    <div class="content">
      <router-link :to="{
                  name: 'DocCollection',
                  params: {
                    cat: dataContent.slugCat,
                    doc:dataContent.id
                  }
                }">
        <div class="imag-content">
          <img v-if="dataContent.image.url" :src="dataContent.image.url" :alt="dataContent.name" />
          <!-- <div class="mask-links" v-if="dataContent.file && Object.values(dataContent.file).length > 0">
            <a v-for="(el, index) in  dataContent.file" :key="index" :href="el.url" target="_blank" download="" title="Descargar archivo en .pdf" class="btn-white -line">
              <span class="nopaddingleft" v-if="el.type.replace(el.type.substr(0, (el.type.indexOf('/') + 1)), '.') == '.epub+zip'">Descargar {{el.type.replace(el.type.substr(0,(el.type.indexOf('/') + 1)),'.').slice(0, -4).trim()}}
              </span>
              <span v-else>
                Descargar {{ el.type.replace(el.type.substr(0, (el.type.indexOf('/') + 1)), '.') }}
              </span>
            </a>

          </div> -->
        </div>
        <p class="date">{{ currentDateTime(dataContent.publish_date)}}</p>
        <p class="cat">{{ dataContent.author }}</p>
        <h2 class="title">{{ dataContent.name }}</h2>
      </router-link>
    </div>

  </section>

</template>

<script lang="js">
  import moment from 'moment'
  export default {
    name: 'card-publicacion-full',
    props: ['dataContent'],

    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
    },
    computed: {

    }
  }


</script>

