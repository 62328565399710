<template lang="html">

  <section class="collections">
    <hero-global img="/img/hero/colecciones.svg" title="Colecciones" />

    <div class="box-content container">
      <ul class="menu-list container">
        <li v-for="(collection, key) in Object.values(store.collections).slice(0,10)" :key="key" :value="key">
          <router-link :class="[idParams == collection.id ? 'router-link-active' : '']" :to="{
                  name: 'colecciones',
                  params: {
                    id: collection.slug
                  }
                }" :title="'Ver ' +collection.name" :alt="'Ver' +collection.name">
            {{ collection.name }}
          </router-link>
        </li>
      </ul>
      <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive" v-model="menuDesplegable" :activeMenuTop="menuTopAnclas">
        <option v-for="(collection, key) in Object.values(store.collections).slice(0,10)" :key="key" :value="collection.slug">
          {{ collection.name }}
        </option>
      </select>
      <div class="content container">

        <!-- <div class="d-flex jcsb aie">
          <b class="title-decoration-bottom font-family-frank-ruhl ">{{colecciones[id]}}</b>
          <div class="container-select">
            <label for="historico">Histórico</label>
            <select name="historico" id="historico">
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
            </select>
          </div>
        </div> -->

        <div class="grid-3" v-if="dataCollection">
          <card-publicacion-full v-for="(collection, index) in  Object.values(dataCollection).reverse().slice(initShow, endShow)" :key="index" :dataContent="collection" :initShow="initShow" :endShow="endShow" />
        </div>
        <paginador v-if="dataCollection" :pageActual="this.page" :totalItems="pagesDocsCollections" @clicked="changePages" @changed="changePages" />
      </div>
    </div>
    <other-links-interest class="links-interest-box container" />
  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import CardPublicacionFull from "@/components/Card/CardPublicacionFull";
  import moment from 'moment'
  import Paginador from "@/components/Global/PaginationGlobal";
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'collections',
    props: ['id','initColection'],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      CardPublicacionFull,
      Paginador
    },
    setup() {
      const store = contentStore();
      store.loadCollections()
      return {
        store
      }
    },
    mounted() {
      this.menuDesplegable = this.id
    },
    data() {
      return {
        menuDesplegable: ''
      }
    },
    methods: {
      changePages(item, page) {
        this.page = item
        let endShow = 9 * item
        let initShow = endShow - 9
        if (initShow > 0) {
          initShow = endShow - 9
        } else {
          initShow = 0
        }
        this.store.setinitPageCollections(initShow)
        this.store.setEndPageCollections(endShow)
        this.store.setPageActualCollections(item)
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: 0,
          });
        }, 200);
      },
    },
    computed: {
      initShow() {
        return this.store.collectionsPagination.init
      },
      endShow() {
        return this.store.collectionsPagination.end
      },
      page() {
        return this.store.collectionsPagination.actual
      },
      dataCollection() {
        if (this.store.collections[this.id]) {
          var arr = Object.values(this.store.collections[this.id].documentsId)
          return arr.sort((a, b) => moment(a.publish_date).unix() - moment(b.publish_date).unix())
        }
        return null
      },
      pagesDocsCollections() {
        if (this.dataCollection.length > 0) {
          var pages = Math.ceil(this.dataCollection.length / 9)
        }
        return pages;
      },
    },
    watch: {
      "menuDesplegable"() {
        this.$router.push({
          name: 'colecciones',
          params: {
            id: this.menuDesplegable
          }
        });
      },
      "id"() {
        this.page = 1,
        this.store.setinitPageCollections(0)
        this.store.setEndPageCollections(9)
        this.store.setPageActualCollections(1)
      }
     
    }
  }


</script>

<style scoped lang="scss">
  .collections {
    .links-interest-box {
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
</style>